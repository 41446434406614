/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function get_window_width(){
    var overflow_x = $('body').css('overflow-x');
    var overflow_y = $('body').css('overflow-y');

    $('body').css('overflow', 'hidden');

    var window_width = $(window).width();

    $('body').css({ 'overflowX': overflow_x, 'overflowY': overflow_y });

    return window_width;
  }

  var window_height = $(window).height();
  var window_width = get_window_width();

  // Theme specific functions
  var HMQ = {
    fade_in_initial_hidden: function() {
      $('.initial_hidden').each(function(i) {
        var delay = (i + 1) * 500;

        $(this).delay(delay).css('opacity', '1');
      });
      // });
    },
    // set_home_banner_height: function() {
    //   $('.banner_holder, .banner, .banner .inner').height( ( window_height + 1 ) );
    // }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // HMQ.set_home_banner_height();

        $(window).load(function() {
          HMQ.fade_in_initial_hidden();
        });

        // $(window).resize(function() {
        //   HMQ.set_home_banner_height();
        // });

        // Menu toggle
        $('#menu_toggle').click(function(){
          $(this).toggleClass('open');
          $('body').toggleClass('menu_open');
          $('#menu_overlay').toggleClass('open');
        });

        // Close menu if clicked outside
        $(document).mouseup(function(e){
          var menu = $('#menu_overlay, #menu_toggle');

          if( !menu.is(e.target) && menu.has(e.target).length !== 1 ){
            $('#menu_toggle').removeClass('open');
            $('body').removeClass('menu_open');
            $('#menu_overlay').removeClass('open');
          }
        });

        // Scroll button on home page
        $('#home_scroll').click(function(event) {
          $("html, body").animate({
            scrollTop: $('#scroll_tabs').offset().top -40
          }, 1000);
        });

        // Change password field type for CF7
        $('#password').attr('type', 'password');
        $('#password_confirm').attr('type', 'password');

        // Remove any <br> tags in forms
        $('form br').remove();

        // $('#accordion').on('show.bs.collapse', function() {



          // console.log('finished');
          // snippet.log("shown");
        // }).on('show.bs.collapse', function() {
          // snippet.log("show");
        // });




        $('#accordion .section h5').click(function(event) {

          $('#accordion').on('show.bs.collapse', function() {
            console.log('test');

            $(this).closest('.arrow').addClass('show');

          });

        //   console.log( $(this).find('.collapse') );


          // if( $(this).find('.collapse').hasClass('show') ){
          //   $('.card_header h5 .arrow').addClass('show');
          // } else {
          //   $('.card_header h5 .arrow').removeClass('show');
          // }
        });

        //Carousel 
        $('#news').owlCarousel({
          margin:10,
          responsiveClass:true,
          responsive:{
            0:{
              items:1,
              nav:true
            },

            992:{
              items:1,
              nav:true
            },
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
